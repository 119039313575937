<script>
import {
  BButton,
  BCard,
  BCardText,
  BCol,
  BForm,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BFormSelect,
  BRow,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import ImageFieldForm from "@/views/components/ImageFieldForm.vue";
import { computed, onMounted, ref } from "@vue/composition-api";
import whitelabelTemplatesModule from "@/views/pages/templates/store/templatesStoreModule";
import formValidation from "@core/comp-functions/forms/form-validation";
import store from "@/store";
import axios from "@/libs/axios";
import i18n from "@/libs/i18n";
import { RequestBuilder } from "@core/utils/requestBuilder";
import vSelect from "vue-select";
import { svgs } from "@/views/components/whitelabel-templates/KB/assets/svgs";
import IconDropdown from "@/views/components/whitelabel-templates/common/IconDropDown.vue";
import {
  showErrorToast,
  showSuccessToast,
} from "@/views/components/whitelabel-templates/common/utils/showToast";
import { axiosErrorHandle } from "@core/utils/errorHandler";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { useToast } from "vue-toastification/composition";
import moreProductsStoreModule
  from "@/views/components/whitelabel-templates/Apostala/config/PageEdit/MoreProductsManagement/store/moreProductsStoreModule";
import apStoreModule from "@/views/components/whitelabel-templates/Apostala/store/apStoreModule";

const BACK_URL = process.env.VUE_APP_URL;

export default {
  name: "index",
  components: {
    IconDropdown,
    BCardText,
    BCard,
    BFormCheckbox,
    BFormSelect,
    BButton,
    ImageFieldForm,
    BFormGroup,
    ValidationProvider,
    ValidationObserver,
    BCol,
    BRow,
    BFormInput,
    BFormInvalidFeedback,
    BForm,
    vSelect,
  },

  props: {
    options: {
      default: () => ({
        action: "edit",
        itemsSelected: null,
        title: "",
        type: "category",
        visible: false,
      }),
    },
  },

  setup(props, { emit }) {
    const MODULE_APP_STORE_NAME = "app-whitelabel-templates";
    const M_PRODUCT_AP_STORE_MODULE_NAME = "ap-m-products-store";
    const MODULE_AP_STORE_NAME = "ap-store";

    // Register modules
    if (!store.hasModule(MODULE_APP_STORE_NAME))
      store.registerModule(MODULE_APP_STORE_NAME, whitelabelTemplatesModule);
    if (!store.hasModule(M_PRODUCT_AP_STORE_MODULE_NAME))
      store.registerModule(M_PRODUCT_AP_STORE_MODULE_NAME, moreProductsStoreModule);
    if (!store.hasModule(MODULE_AP_STORE_NAME))
      store.registerModule(MODULE_AP_STORE_NAME, apStoreModule);

    const mProduct = ref(null);
    const loading = ref(false);
    const lobbies = ref([]);
    const backURL = ref(BACK_URL);
    const mProductSelected = ref({});
    const lobbySelected = ref({});
    const products = ref([]);

    const rBuild = RequestBuilder();
    const toast = useToast();

    const selectOptions = ref([
      { text: i18n.t("left"), value: 1 },
      { text: i18n.t("center"), value: 2 },
      { text: i18n.t("right"), value: 3 },
    ]);

    const variants = ref([
      { value: "casino", text: "Casino" },
      { value: "sports", text: "Sports" },
      { value: "racing", text: "Racing" },
    ]);

    const icons = ref(svgs);

    const editTemplate = computed(
      () => store.state[MODULE_APP_STORE_NAME].editTemplate
    );

    const isEditMode = computed(() => props.options.action === "edit");

    const resetMProduct = () => {
      mProduct.value = {
        name: "",
        href: "",
        sub_title: "",
        text: "",
        title: "",
        image: "",
        svgData: "",
        variant: "",
        buttonType: "link",
        position: "0",
      };
    };

    const resetSelected = (selectedRef) => {
      selectedRef.value = { position: 0 };
    };

    const updateMProductFromSelection = (val, type) => {
      if (!val) {
        resetSelected(type === "lobby" ? lobbySelected : mProductSelected);
        return;
      }

      if (val.hasOwnProperty("position")) {
        mProduct.value.position = val.position;
      } else {
        if (type === "lobby") {
          lobbySelected.value = val;
          mProduct.value.id = val._id;
          mProduct.value.title = val.name;
          mProduct.value.href = `/lobby?id=${val._id}`;
          mProduct.value.position = val.position || 0;
        } else {
          mProductSelected.value = val;
          mProduct.value.id = val.productId;
          mProduct.value.title = val.name;
          mProduct.value.href = `/product/${val.productId}`;
        }
      }
      updatePreview();
    };

    const lobby = computed({
      get: () => lobbySelected.value,
      set: (val) => updateMProductFromSelection(val, "lobby"),
    });

    const product = computed({
      get: () => productSelected.value,
      set: (val) => updateMProductFromSelection(val, "product"),
    });

    onMounted(() => {
      resetMProduct();
      const { itemsSelected } = props.options;
      if (itemsSelected) mProduct.value = itemsSelected;
      getLobbies();
      getProducts();
      updatePreview();
    });

    const getLobbies = async () => {
      const whitelabelId = store.state.whitelabelCurrencyNabvar.whitelabel._id;
      try {
        const response = await axios.get(backURL.value + "/lobbies", {
          params: {
            whitelabelId,
          },
        });
        lobbies.value = response.data.lobbys;
      } catch (err) {
        console.error(err);
      }
    };

    const getProducts = async () => {
      try {
        const clientId = store.state.whitelabelCurrencyNabvar.whitelabel.client;
        const response = await axios.get(
          `${backURL.value}/clients/availProd/${clientId}`
        );
        products.value = response.data.map((element) => ({
          productId: element.product.productId,
          name: element.product.name,
        }));
      } catch (err) {
        console.error(err);
      }
    };

    const onSubmit = async () => {
      if (isEditMode.value) {
        await updateMProduct();
      } else {
        await createMProduct();
      }
    };

    const sortMProducts = () => {
      const updatedTemplate = {
        ...editTemplate.value,
        templateData: {
          ...editTemplate.value.templateData,
          mProducts: [...editTemplate.value.templateData.mProducts].sort(
            (a, b) => a.position - b.position
          ),
        },
      };

      store.commit(
        "app-whitelabel-templates/SET_EDIT_TEMPLATE",
        updatedTemplate
      );
    };

    const updateTemplate = async () => {
      try {
        const template = await store.dispatch(
          "app-whitelabel-templates/updateTemplate",
          {
            template: editTemplate.value,
            toast,
            path: "templateData.mProducts",
          }
        );
      } catch (error) {
        showErrorToast(
          toast,
          i18n.t("error_updating_template"),
          axiosErrorHandle(error)
        );
      }
    };

    const createMProducts = async () => {
      loading.value = true;
      category.value.whitelabelId =
        store.state.whitelabelCurrencyNabvar.whitelabel._id;
      category.value.templateId = editTemplate.value._id;

      try {
        const response = await store.dispatch(
          "ap-m-product-store/addMProduct",
          rBuild.clean(mProduct.value)
        );

        showSuccessToast(toast, "Product", i18n.t("m_product_created"));
        sortMProducts();
        updatePreview(response.data);
        resetAll();
        await updateTemplate();
        emit("created", response.data);
      } catch (error) {
        showErrorToast(
          toast,
          i18n.t("error_creating_update_m_product"),
          axiosErrorHandle(error)
        );
      } finally {
        loading.value = false;
      }
    };

    const updateMProduct = async () => {
      loading.value = true;
      try {
        const response = await store.dispatch(
          "ap-m-product-store/updateMProduct",
          {
            id: mProduct.value._id,
            mProductData: rBuild.clean(mProduct.value),
          }
        );

        showSuccessToast(toast, "More Products", i18n.t("m_product_updated"));
        sortMProducts();
        updatePreview(response.data);
        await updateTemplate();
        resetAll();
        emit("updated", response.data);
      } catch (error) {
        showErrorToast(
          toast,
          i18n.t("error_creating_update_m_product"),
          axiosErrorHandle(error)
        );
      } finally {
        loading.value = false;
      }
    };

    const resetAll = () => {
      store.commit("kb-store/SET_CLICKED_CATEGORY", null);
    };

    const updatePreview = (value) => {
      sortMProducts();
      const template = editTemplate.value;
      const categoryM = value || mProduct.value;
      const index = template.templateData.mProducts.findIndex(
        (obj) => obj._id === categoryM._id
      );
      if (index !== -1) {
        store.commit("app-whitelabel-templates/SET_EDIT_TEMPLATE", {
          ...template,
          templateData: {
            ...template.templateData,
            categories: template.templateData.mProducts.map((category, i) =>
              i === index ? categoryM : mProduct
            ),
          },
        });
      }
    };

    const { refFormObserver, getValidationState, resetForm } = formValidation(
      () => {}
    );

    const onUploadedImage = (data) => {
      if (data?.files.length) {
        const image = data.files[data.files.length - 1];
        mProduct.value.image = image || "";
        updatePreview();
      }
    };

    const errorInLoadImage = (error) => {
      toast({
        component: ToastificationContent,
        position: "top-right",
        props: {
          title: error,
          icon: "AlertTriangleIcon",
          variant: "danger",
        },
      });
    };

    const onIconSelected = (icon) => {
      mProduct.value.svgData = icon.value;
      updatePreview();
    };

    const resetData = () => {
      store.commit(
        "app-whitelabel-templates/RESET_EDIT_TEMPLATE",
        "templateData.mProducts"
      );
      store.commit("app-whitelabel-templates/RE_RENDER_TEMPLATE");
      emit("reset");
    };

    return {
      isEditMode,
      onSubmit,
      updateTemplate,
      resetData,
      editTemplate,
      refFormObserver,
      getValidationState,
      resetForm,
      selectOptions,
      mProduct,
      loading,
      variants,
      icons,
      lobbies,
      products,
      onUploadedImage,
      onIconSelected,
      lobby,
      product,
      errorInLoadImage,
      updatePreview,
    };
  },
};
</script>

<template>
  <div>
    <validation-observer
      ref="refFormObserver"
      #default="{ handleSubmit, invalid }"
    >
      <b-form
        v-if="mProduct"
        @submit.prevent="handleSubmit(onSubmit)"
        @reset.prevent="resetForm"
      >
        <b-list-group-item style="background: none" class="px-0">
          <b-row>
            <validation-provider
              class="w-100"
              #default="validationContext"
              name="name"
              rules="required"
            >
              <b-col cols="12">
                <b-form-group label="name" label-for="c-name">
                  <template #label>{{ $t("labels.name") }}</template>
                  <b-form-input
                    id="c-name"
                    type="text"
                    v-model="mProduct.name"
                    :state="getValidationState(validationContext)"
                  />
                  <small class="text-danger">
                    {{ validationContext.errors[0] }}
                  </small>
                </b-form-group>
              </b-col>
            </validation-provider>
          </b-row>

          <b-row>
            <b-col cols="12">
              <b-form-group label="image" label-for="c-image">
                <span>651x660</span>
                <image-field-form
                  :single-button="true"
                  :text="$t('labels.image')"
                  size-validation="651x660"
                  class="w-100 mt-1"
                  @uploadedImage="onUploadedImage"
                  @error="errorInLoadImage"
                  path="categories"
                >
                </image-field-form>
                <img
                  style="max-width: 100%"
                  v-if="mProduct.image"
                  :src="mProduct.image"
                  alt="Uploaded Image"
                  class="mt-1 rounded"
                />
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col cols="12">
              <b-form-group label-for="c-image">
                <template #label>{{ $t("button_type") }}</template>
                <v-select
                  v-model="mProduct.buttonType"
                  label="text"
                  :options="['link', 'product', 'lobby']"
                />
              </b-form-group>
            </b-col>
          </b-row>

          <b-row v-if="mProduct.buttonType === 'lobby'">
            <b-col cols="12">
              <b-form-group label-for="lobby">
                <template #label>{{ $t("lobby") }}</template>
                <v-select
                  rulses="required"
                  v-model="lobby"
                  label="name"
                  track-by="name"
                  :options="lobbies"
                />
              </b-form-group>
            </b-col>
          </b-row>

          <b-row v-if="mProduct.buttonType === 'product'">
            <b-col cols="12">
              <b-form-group label-for="products">
                <template #label>{{ $t("labels.products") }} </template>
                <v-select
                  v-model="product"
                  required
                  label="name"
                  :options="products"
                />
              </b-form-group>
            </b-col>
          </b-row>

          <b-row v-if="mProduct.buttonType === 'link'">
            <b-col cols="12">
              <validation-provider
                #default="validationContext"
                name="href"
                rules="required"
              >
                <b-form-group label="Link" label-for="c-href">
                  <template #label>{{ $t("labels.link") }}</template>
                  <b-form-input
                    id="c-href"
                    type="text"
                    v-model="mProduct.href"
                    :state="getValidationState(validationContext)"
                  />
                  <small class="text-danger">
                    {{ validationContext.errors[0] }}
                  </small>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>

          <b-row>
            <validation-provider
              #default="validationContext"
              name="title"
              class="w-100"
              rules="required"
            >
              <b-col cols="12">
                <b-form-group label="title" label-for="c-title">
                  <template #label>{{ $t("labels.title") }}</template>
                  <b-form-input
                    id="c-title"
                    type="text"
                    v-model="mProduct.title"
                    :state="getValidationState(validationContext)"
                  />
                  <small class="text-danger">
                    {{ validationContext.errors[0] }}
                  </small>
                </b-form-group>
              </b-col>
            </validation-provider>
          </b-row>

          <b-row>
            <b-col cols="12">
              <validation-provider #default="validationContext" name="svgData">
                <b-form-group label-for="icon">
                  <template #label>Icon</template>
                  <b-input-group>
                    <IconDropdown
                      :icons="icons"
                      :selected-icon-prop="mProduct.svgData"
                      @icon-selected="onIconSelected($event, mProduct)"
                    ></IconDropdown>
                  </b-input-group>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>

          <b-row>
            <b-col cols="12">
              <validation-provider
                #default="validationContext"
                name="subtitle"
                rules="required"
              >
                <b-form-group label="subtitle" label-for="c-subtitle">
                  <template #label>{{ $t("subtitle") }}</template>
                  <b-form-input
                    @input="updatePreview()"
                    id="c-subtitle"
                    type="text"
                    v-model="mProduct.sub_title"
                    :state="getValidationState(validationContext)"
                  />
                  <small class="text-danger">
                    {{ validationContext.errors[0] }}
                  </small>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>

          <b-row>
            <b-col cols="12">
              <validation-provider
                #default="validationContext"
                name="variant"
                rules="required"
              >
                <b-form-group label="variant" label-for="c-variant">
                  <template #label>{{ $t("labels.variant") }}</template>

                  <b-form-select
                    @change="updatePreview()"
                    v-model="mProduct.variant"
                    :options="variants"
                  ></b-form-select>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>

          <b-row>
            <b-col cols="12">
              <validation-provider
                #default="validationContext"
                name="text"
                rules="required"
              >
                <b-form-group label="text" label-for="cta_text">
                  <template #label>{{ $t("labels.text") }}</template>

                  <b-form-textarea
                    @input="updatePreview()"
                    rows="6"
                    v-model="mProduct.text"
                  ></b-form-textarea>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>

          <b-row>
            <b-col cols="12">
              <validation-provider
                #default="validationContext"
                name="position"
                rules="required"
              >
                <b-form-group label-for="position">
                  <template #label>position</template>
                  <b-form-input
                    @input="updatePreview()"
                    id="position"
                    type="number"
                    min="0"
                    required
                    v-model="mProduct.position"
                  />
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>
        </b-list-group-item>

        <div class="mt-4 d-flex flex-column" style="gap: 5px">
          <b-button
            :disabled="loading || invalid"
            size="md"
            type="submit"
            class="w-100"
            variant="success"
          >
            <b-spinner small label="Loading..." v-if="loading"></b-spinner>
            {{
              isEditMode
                ? $t("buttons.update_and_apply")
                : $t("buttons.create_and_apply")
            }}
          </b-button>
          <b-button @click="resetData()"> {{ $t("buttons.cancel") }}</b-button>
        </div>
      </b-form>
    </validation-observer>
  </div>
</template>

<style scoped lang="scss"></style>
