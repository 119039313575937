<script>
import ImageFieldForm from "@/views/components/ImageFieldForm.vue";
import {BButton, BCol, BFormGroup, BFormInput, BFormSelect} from "bootstrap-vue";
import {ValidationProvider} from "vee-validate";
import {computed, ref, watch} from "@vue/composition-api";

export default {
  name: "rightSectionItem",
  components: {BCol, BFormGroup, BFormSelect, BFormInput, ValidationProvider, BButton, ImageFieldForm},
  props: {
    items: {
      type: Array,
      required: true
    }
  },
  setup(props, {emit}) {
    const formValid = ref(false)
    const index = ref(null)

    const settings = ref({
      status: 'list',
      action: 'add',
    })

    const item = ref({
      content: ''
    })

    const checkForm = () => {
      const required = ['content']
      formValid.value = false

      required.forEach(key => {
        if (item.value[key]) {
          formValid.value = true
        }
      })
    }


    const count = computed(() => {
      return props.items.length || 0;
    });


    const onAdd = () => {
      index.value = null
      item.value = {content: ''}
      settings.value.status = 'form'
      emit('status', settings.value.status)
    }


    const onEdit = (value, i) => {
      item.value = {...value}
      index.value = i
      settings.value.status = 'form'
      emit('status', settings.value.status)
    }

    const onDelete = (index) => {
      emit('delete', index)
      settings.value.status = 'list'
      emit('status', settings.value.status)
    }


    watch(() => item, () => {
      checkForm()
    }, {deep: true})

    const itemHandler = () => {
      if (!formValid.value) {
        return
      }

      if (index.value !== null) {
        emit('edit', {value: item.value, index: index.value})
      } else {
        emit('add', item.value)
      }

      settings.value.status = 'list'
      emit('status', settings.value.status)
    }

    const onCancel = () => {
      item.value = {content: ''}
      settings.value.status = 'list'
      index.value = null
      emit('status', settings.value.status)
    }


    return {
      settings,
      formValid,
      itemHandler,
      item,
      count,
      onAdd,
      onEdit,
      onDelete,
      onCancel
    }
  }
}
</script>

<template>
  <div>
    <div v-if="settings.status === 'form'">

      <div class="mx-1">
        <h6>{{ settings.action === 'add' ? 'Add' : 'Edit' }} item</h6>
        <hr>
      </div>


      <b-col class="border-md-left" cols="12">
        <b-form-group label-for="content">
          <template #label>{{ $t("content") }}</template>
          <b-form-input
              id="content"
              required
              type="text"
              v-model="item.content">
          </b-form-input>
        </b-form-group>
      </b-col>

      <div class="row px-1 pb-2">
        <div class="col-6">
          <b-button :disabled="!formValid"
                    size="sm"
                    class="w-100 mt-1"
                    @click="itemHandler"
                    variant="primary">
            save Item
          </b-button>
        </div>
        <div class="col-6">
          <b-button size="sm" class="w-100 mt-1" variant="secondary" @click="onCancel()">
            {{ $t("buttons.cancel") }}
          </b-button>
        </div>
      </div>

    </div>
    <div v-if="settings.status === 'list' && items">
      <div class="row">

      </div>
      <div class="mx-1">
        <h6> Items </h6>
      </div>

      <div class="mx-1" v-if="settings.status === 'list'">
        <div class="d-flex mt-2 justify-content-end">
          <b-button-group class="w-100">
            <b-button variant="outline-primary" :disabled="count >= 12" @click="onAdd()">{{
                $t('add_item')
              }}
            </b-button>
            <b-button variant="primary">{{ count }}/12</b-button>
          </b-button-group>
        </div>
      </div>

      <b-list-group class="mt-1 mx-1" flush style="max-height: 380px; overflow-y: scroll">
        <b-list-group-item class="d-inline flex bg-transparent" v-for="(item, index) in items" :key="index">
          <div class="d-inline">
            <small>{{ item.content }}</small>
          </div>
          <div class="float-right">
            <b-button-group size="sm">
              <b-button @click="onEdit(item, index)" variant="outline-secondary" class="border-0">
                <feather-icon icon="EditIcon"/>
              </b-button>
              <b-button @click="onDelete(index)" variant="outline-secondary" class="border-0">
                <feather-icon icon="TrashIcon"/>
              </b-button>
            </b-button-group>
          </div>
        </b-list-group-item>
      </b-list-group>

      <div v-if="items.length === 0" class="py-3 text-center">
        {{ $t('list_empty') }}
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">

</style>